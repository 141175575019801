import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { Container, Layout } from "components";
import BlogAuthor from "../BlogAuthor";
import BlogAuthorLatestPosts from "../../BlogAuthorLatestPosts/BlogAuthorLatestPosts";
import "./BlogAuthorTemplate.scss";
import MetatagNormalized from "../../../../components/MetatagNormalized/MetatagNormalized";
import { formattedMetaTags } from "helpers";

export default ({ data }) => {
  const block = `BlogAuthorTemplate`;
  const author = data.contentfulSecOktaComAuthor || [];
  const posts = data.allContentfulSecOktaComBlogPost.nodes || [];

  const metatags = (
    <MetatagNormalized
      // trim the bio description for SEO purposes.
      data={formattedMetaTags(
        author.name,
        `/hackers/${author.slug}`,
        author.bio.bio.substring(0, 160)
      )}
      skipRels={["canonical"]}
      replacements={[
        {
          from: "https://cms.oktaweb.dev/sec.okta.com",
          to: "https://sec.okta.com",
        },
      ]}
    />
  );

  const orderedPosts = posts.sort(
    (a, b) => Date.parse(b.date) - Date.parse(a.date)
  );

  const filterBlogPostsByAuthor = orderedPosts
    .filter(
      (p) =>
        Array.isArray(p.secAuthor) &&
        p.secAuthor.some((a) => a.id === author.id)
    )
    .slice(0, 3)
    .sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

  return (
    <Layout variant={`light`}>
      {metatags}
      <Container variant={`narrow`}>
        <div className={block}>
          <h1 className={`${block}__title`}>{author.name}</h1>
          <BlogAuthor authors={[author]} />
          {filterBlogPostsByAuthor.length > 0 && (
            <BlogAuthorLatestPosts posts={filterBlogPostsByAuthor} />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String, $node_locale: String) {
    contentfulSecOktaComAuthor(
      slug: { eq: $slug }
      node_locale: { eq: $node_locale }
    ) {
      name
      slug
      id
      jobTitle
      bio {
        bio
      }
      node_locale
      image {
        gatsbyImageData(width: 58)
      }
      sec_okta_com___blog_post {
        slug
        title
      }
    }
    allContentfulSecOktaComBlogPost(filter: { node_locale: { eq: "en" } }) {
      nodes {
        slug
        id
        title
        date
        sys {
          contentType {
            sys {
              id
              linkType
              type
            }
          }
          type
        }
        body {
          raw
        }
        summary {
          summary
        }
        updatedAt
        secAuthor {
          id
          bio {
            bio
          }
          image {
            url
          }
          name
          jobTitle
          slug
          node_locale
        }
      }
    }
  }
`;
