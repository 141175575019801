import React from "react"
import PropTypes from "prop-types"

const Paragraph = (props, id) => {
  const { type } = props;
  if (!paragraphsMapping[type]) {
    return (
      <p style={{ color: `red` }}>Missing Paragraph type {type}. Cannot show paragraph n°{id}.</p>
    );
  }

  const Component = paragraphsMapping[type];

  return <Component key={id} id={id} {...props} node={props} />
}

Paragraph.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
}

export default Paragraph
