import React from "react";
import { Link } from "gatsby";
import BlogAuthor from "../BlogAuthor/BlogAuthor";
import "./BlogTeaser.scss";
import blogTeaserText from "../blogTeaserText/blogTeaserText";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import articlePath from "../../../helpers/articlePath";

const BlogTeaser = ({ body, summary, date, secAuthor, slug, title, id }) => {
  // const slug = content.path.alias.replace('/sec.okta.com/', '/')
  const created = new Date(date);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Build the teaser.
  if (!summary?.summary && !body.raw) {
    return null;
  }

  const teaser = summary?.summary?.replace(/(<([^>]+)>)/gi, "") ?? blogTeaserText({
    text: documentToHtmlString(JSON.parse(body.raw)).replace(
      /(<\/[^>]*>)\s*(?=[^>]*>)/g,
      " "
    ),
    maxLength: 500
  })

  slug = articlePath(slug);

  return (
    <div className="BlogTeaser" key={`BlogTeaser-${id}`}>
      <div className="BlogTeaser__meta">
        <div className="BlogTeaser__by">
          <BlogAuthor viewMode={`byline-small`} authors={secAuthor} />
        </div>
        <time className="BlogTeaser__created" dateTime={`${created.getFullYear()}-${(`0${created.getMonth()+1}`).substr(-2, 2)}-${(`0${created.getDate()}`).substr(-2, 2)}`}>{months[created.getMonth()]} {created.getDate()}, {created.getFullYear()}</time>
        </div>
      <h2 className="BlogTeaser__title"><Link to={slug}>{title}</Link></h2>
      <div className="BlogTeaser__teaser" dangerouslySetInnerHTML={{__html: teaser}} />
      <div className="BlogTeaser__more">
        <Link to={slug}>Read more</Link>
      </div>
    </div>
  )
}

export default BlogTeaser;
